<template>
	<div>
		<section class="grid-view wishlist-items">
			<b-card v-for="image in filteredImages" :key="image._id" class="ecommerce-card" no-body>
				<div class="item-img text-center">
					<!-- <b-link :to="{ name: 'apps-e-commerce-product-details', params: { slug: image.slug } }">
					<b-img :alt="`${image.label}`" fluid class="card-img-top" :src="image.url" />
				</b-link> -->
					<!-- <span>
					<b-img :alt="`${image.label}`" fluid class="card-img-top" :src="image.url" />
				</span> -->
					<b-link :href="image.url" target="_blank">
						<b-img :alt="`${image.label}`" fluid class="card-img-top" :src="image.url" />
					</b-link>
				</div>

				<!-- Product Details -->
				<b-card-body>
					<div class="item-wrapper">
						<!-- <div class="item-rating">
						<ul class="unstyled-list list-inline">
							<li v-for="star in 5" :key="star" class="ratings-list-item" :class="{ 'ml-25': star - 1 }">
								<feather-icon
									icon="StarIcon"
									size="16"
									:class="[
										{ 'fill-current': star <= image.rating },
										star <= image.rating ? 'text-warning' : 'text-muted',
									]"
								/>
							</li>
						</ul>
					</div>
					<div>
						<h6 class="item-price">${{ image.price }}</h6>
					</div> -->
					</div>
					<h6 class="item-name">
						<!-- <b-link class="text-body" :to="{ name: 'apps-e-commerce-product-details', params: { slug: image.slug } }"> -->
						{{ image.label }}
						<!-- </b-link> -->
					</h6>
					<b-card-text class="item-description">
						<div v-if="image.customerId">
							Uploader:
							<b> {{ image.customerId.firstname }} {{ image.customerId.lastname }} ({{ image.customerId.email }})</b>
						</div>
						<div>
							Größe: <b>{{ image.width }} x {{ image.height }} Pixel</b>
						</div>
						<div>
							Datei-Typ: <b>{{ image.fileType.replace('%2B', '+') }}</b>
						</div>
						<div>
							Datum: <b>{{ formatDate(image.createdAt) }}</b>
						</div>
						<div v-if="image.requests.length > 0">
							Projekte:
							<b-link
								class="text-body"
								:to="{ name: 'wercbrain-request-details', params: { requestId: request._id } }"
								v-for="request in image.requests"
								:key="request._id"
							>
								<b>{{ request.publicId }}</b>
							</b-link>
						</div>
					</b-card-text>
				</b-card-body>

				<!-- Action Buttons -->
				<!-- <div class="item-options text-center">
				<b-button variant="light" class="btn-wishlist remove-wishlist" @click="removeItem">
					<feather-icon icon="XIcon" />
					<span>Remove</span>
				</b-button>
				<b-button variant="primary" class="btn-cart move-cart" @click="showDetails">
					<feather-icon icon="ShoppingCartIcon" class="mr-50" />
					<span>{{ image.isInCart ? 'View In Cart' : 'Move to Cart' }}</span>
				</b-button>
			</div> -->
			</b-card>
		</section>
		<!-- Pagination -->
		<section>
			<b-row>
				<b-col cols="12">
					<b-pagination
						v-model="filters.page"
						:total-rows="totalImages"
						:per-page="filters.perPage"
						first-number
						align="center"
						last-number
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
		</section>
		<!-- Sidebar -->
		<portal to="content-renderer-sidebar-detached-left">
			<filter-sidebar :filters="filters" :filter-options="filterOptions" :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" />
		</portal>
	</div>
</template>

<script>
import { BCard, BCardBody, BImg, BCardText, BLink, BButton, BPagination, BRow, BCol } from 'bootstrap-vue'
import store from '@/store'
import { ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import FilterSidebar from './FilterSidebar.vue'

export default {
	name: 'FileExplorer',
	components: {
		BCard,
		BCardBody,
		BImg,
		BCardText,
		BLink,
		BButton,
		BPagination,
		BRow,
		BCol,
		FilterSidebar,
	},
	data() {
		return {
			filterOptions: {
				context: [
					{ text: 'Alle', value: 'all' },
					{ text: 'In Projekt verwendet', value: 'used' },
					{ text: 'Unbenutzt', value: 'unused' },
				],
			},
		}
	},
	watch: {
		filters: {
			handler(newValue, oldValue) {
				// Note: `newValue` will be equal to `oldValue` here
				// on nested mutations as long as the object itself
				// hasn't been replaced.
				store
					.dispatch('Files/fetchFiles', newValue)
					.then((response) => {
						if (response && response.files) {
							this.images = response.files
							this.totalImages = response.filesCount
						} else console.log(response)
					})
					.catch((err) => {
						console.error(err)
					})
			},
			deep: true,
		},
	},
	setup() {
		const images = ref([])
		const filters = ref({
			q: '',
			context: 'all',
			page: 1,
			perPage: 20,
		})
		const totalImages = ref(null)

		const fetchImages = () => {
			store
				.dispatch('Files/fetchFiles', filters.value)
				.then((response) => {
					if (response && response.files) {
						images.value = response.files
						totalImages.value = response.filesCount
					} else console.log(response)
				})
				.catch((err) => {
					console.error(err)
				})
		}

		fetchImages()

		const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

		return {
			images,
			totalImages,
			filters,
			mqShallShowLeftSidebar,
		}
	},
	computed: {
		filteredImages() {
			return this.images.filter((i) => i.type === 'upload')
		},
	},
	methods: {
		formatDate(date) {
			return dayjs(date).format('DD.MM.YYYY')
		},
		removeItem() {
			console.log('removeItem')
		},
		showDetails() {
			console.log('showDetails')
		},
	},
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';

.item-img {
	justify-content: center;
	background: rgba(0, 0, 0, 0.03);
	img {
		max-height: 150px;
	}
}

.item-description {
	> div {
		white-space: normal;
	}
}
</style>
